import request from "@/core/services/axios";

// 联考 - 考试扫描点管理 - 列表查询;
export function examscanpointList(query) {
  return request({
    url: "/exam/examscanpoint/list",
    method: "get",
    params: query,
  });
}

// 联考-添加扫描点-获取可选择学校列表
export function otherschool(query) {
  return request({
    url: "/exam/examscanpoint/get/otherschool",
    method: "get",
    params: query,
  });
}

// 联考-获取可选择用户列表
export function otheruser(query) {
  return request({
    url: "/exam/examscanpoint/get/otheruser",
    method: "get",
    params: query,
  });
}

// 联考-删除扫描点
export function deletePoint(query) {
  return request({
    url: "/exam/examscanpoint/delete",
    method: "get",
    params: query,
  });
}

// 联考-导出扫描点
export function pointExport(query) {
  return request({
    url: "/exam/examscanpoint/export",
    method: "get",
    params: query,
  });
}

// 联考-添加-修改扫描点以及关联学校
export function saveorupdate(data) {
  return request({
    url: "/exam/examscanpoint/saveorupdate",
    method: "post",
    data: data,
  });
}

// 联考-添加-修改扫描点关联用户
export function saveorupdateUser(data) {
  return request({
    url: "/exam/examscanpoint/saveorupdate/user",
    method: "post",
    data: data,
  });
}
