import request from "@/core/services/axios";
export function examteacher(params) {
  // 阅卷老师管理-分页查询
  return request({
    url: "/exam/examteacher/page",
    method: "post",
    params: params,
  });
}
export function existsubject(params) {
  // 阅卷老师管理-查询科目
  return request({
    url: "/exam/examteacher/get/existsubject",
    method: "get",
    params: params,
  });
}
export function addExamteacher(params) {
  // 阅卷老师管理-新增
  return request({
    url: "/exam/examteacher/save",
    method: "post",
    data: params,
  });
}
export function delExamteacher(params) {
  // 阅卷老师管理-删除
  return request({
    url: "/exam/examteacher/delete",
    method: "DELETE",
    params: params,
  });
}
export function editExamteacher(params) {
  // 阅卷老师管理-修改
  return request({
    url: "/exam/examteacher/update",
    method: "put",
    data: params,
  });
}
export function schoolteachertype(params) {
  // 联考获取阅卷老师分类
  return request({
    url: "/exam/examteacher/schoolteachertype/examunion",
    method: "post",
    data: params,
  });
}
export function examinationuserBatch(params) {
  // 阅卷老师管理-导入联考用户-批量导入
  return request({
    url: "/exam/examteacher/import/examinationuser/batch",
    method: "post",
    data: params,
  });
}
export function examinationuserQuery(params) {
  // 阅卷老师管理-导入联考用户-条件搜索导入
  return request({
    url: "/exam/examteacher/import/examinationuser/query",
    method: "post",
    data: params,
  });
}

export function schoolteacher(params) {
  // 阅卷老师管理-导入校内用户
  return request({
    url: "/exam/examteacher/import/schoolteacher",
    method: "post",
    data: params,
  });
}

export function deletebyschoolid(params) {
  // 阅卷老师管理-导入校内用户
  return request({
    url: "/exam/examteacher/delete/byschoolid",
    method: "delete",
    data: params,
  });
}

export function deleteBatch(params) {
  // 阅卷老师管理-批量删除
  return request({
    url: "/exam/examteacher/delete/batch",
    method: "post",
    data: params,
  });
}
export function getSchoolteacher(params) {
  // 阅卷老师管理-导入校内用户--查询
  return request({
    url: "/exam/examteacher/get/schoolteacher",
    method: "post",
    data: params,
  });
}

export function saveUnionuser(params) {
  //添加阅卷教师-引用联考端用户
  return request({
    url: "/exam/examteacher/save/unionuser",
    method: "post",
    data: params,
  });
}

export function saveSchooluser(params) {
  //添加阅卷教师-引用校端用户
  return request({
    url: "/exam/examteacher/save/schooluser",
    method: "post",
    data: params,
  });
}

export function exportExamTeacher(params) {
  // 导出阅卷老师
  return request({
    url: "/exam/examteacher/export",
    method: "get",
    params: params,
  });
}

export function clearexamschools(params) {
  // 清空阅卷教师时获取学校
  return request({
    url: "/exam/examteacher/get/clearexamschools",
    method: "get",
    params: params,
  });
}

export function queryUnionuser(params) {
  // 获取联考教师用户
  return request({
    url: "/exam/examteacher/query/unionuser",
    method: "get",
    params: params,
  });
}

export function existexamschool(params) {
  // 获取当前考试下的校端教师
  return request({
    url: "/exam/examteacher/get/existexamschool",
    method: "get",
    params: params,
  });
}

export function unionsubschool(params) {
  // 获取所有的学校列表
  return request({
    url: "/exam/examteacher/get/unionsubschool",
    method: "get",
    params: params,
  });
}

export function schoollistbyexamidV2(params) {
  // 获取当前考试下所有的学校列表
  return request({
    url: "/exam/exam/get/schoollistbyexamidV2",
    method: "get",
    params: params,
  });
}

export function allschoollistbyexamid(params) {
  // 获取当前考试下所有的学校列表
  return request({
    url: "/exam/exam/get/schoollistbyexamid",
    method: "get",
    params: params,
  });
}

export function querySchooluser(params) {
  // 查询--添加阅卷教师-引用校端教师
  return request({
    url: "/exam/examteacher/query/schooluser",
    method: "get",
    params: params,
  });
}

export function getschooluseryear(params) {
  // 查询--添加阅卷教师-引用校端教师
  return request({
    url: "/exam/examteacher/query/schooluseryear",
    method: "get",
    params: params,
  });
}
